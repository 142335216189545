export const prefixIso2Code: {
  prefix: string;
  iso2: string;
}[] = [{
  prefix: '+93',
  iso2: 'AF'
}, {
  prefix: '+355',
  iso2: 'AL'
}, {
  prefix: '+213',
  iso2: 'DZ'
}, {
  prefix: '+376',
  iso2: 'AD'
}, {
  prefix: '+244',
  iso2: 'AO'
}, {
  prefix: '+1264',
  iso2: 'AI'
}, {
  prefix: '+1268',
  iso2: 'AG'
}, {
  prefix: '+54',
  iso2: 'AR'
}, {
  prefix: '+374',
  iso2: 'AM'
}, {
  prefix: '+297',
  iso2: 'AW'
}, {
  prefix: '+61',
  iso2: 'AU'
}, {
  prefix: '+43',
  iso2: 'AT'
}, {
  prefix: '+994',
  iso2: 'AZ'
}, {
  prefix: '+1242',
  iso2: 'BS'
}, {
  prefix: '+973',
  iso2: 'BH'
}, {
  prefix: '+880',
  iso2: 'BD'
}, {
  prefix: '+1246',
  iso2: 'BB'
}, {
  prefix: '+375',
  iso2: 'BY'
}, {
  prefix: '+32',
  iso2: 'BE'
}, {
  prefix: '+501',
  iso2: 'BZ'
}, {
  prefix: '+229',
  iso2: 'BJ'
}, {
  prefix: '+1441',
  iso2: 'BM'
}, {
  prefix: '+975',
  iso2: 'BT'
}, {
  prefix: '+591',
  iso2: 'BO'
}, {
  prefix: '+387',
  iso2: 'BA'
}, {
  prefix: '+267',
  iso2: 'BW'
}, {
  prefix: '+55',
  iso2: 'BR'
}, {
  prefix: '+673',
  iso2: 'BN'
}, {
  prefix: '+359',
  iso2: 'BG'
}, {
  prefix: '+226',
  iso2: 'BF'
}, {
  prefix: '+257',
  iso2: 'BI'
}, {
  prefix: '+855',
  iso2: 'KH'
}, {
  prefix: '+237',
  iso2: 'CM'
}, {
  prefix: '+1',
  iso2: 'CA'
}, {
  prefix: '+238',
  iso2: 'CV'
}, {
  prefix: '+236',
  iso2: 'CF'
}, {
  prefix: '+235',
  iso2: 'TD'
}, {
  prefix: '+56',
  iso2: 'CL'
}, {
  prefix: '+86',
  iso2: 'CN'
}, {
  prefix: '+57',
  iso2: 'CO'
}, {
  prefix: '+269',
  iso2: 'KM'
}, {
  prefix: '+243',
  iso2: 'CD'
}, {
  prefix: '+242',
  iso2: 'CG'
}, {
  prefix: '+506',
  iso2: 'CR'
}, {
  prefix: '+225',
  iso2: 'CI'
}, {
  prefix: '+385',
  iso2: 'HR'
}, {
  prefix: '+53',
  iso2: 'CU'
}, {
  prefix: '+357',
  iso2: 'CY'
}, {
  prefix: '+420',
  iso2: 'CZ'
}, {
  prefix: '+45',
  iso2: 'DK'
}, {
  prefix: '+253',
  iso2: 'DJ'
}, {
  prefix: '+1767',
  iso2: 'DM'
}, {
  prefix: '+593',
  iso2: 'EC'
}, {
  prefix: '+20',
  iso2: 'EG'
}, {
  prefix: '+503',
  iso2: 'SV'
}, {
  prefix: '+240',
  iso2: 'GQ'
}, {
  prefix: '+291',
  iso2: 'ER'
}, {
  prefix: '+372',
  iso2: 'EE'
}, {
  prefix: '+251',
  iso2: 'ET'
}, {
  prefix: '+500',
  iso2: 'FK'
}, {
  prefix: '+298',
  iso2: 'FO'
}, {
  prefix: '+679',
  iso2: 'FJ'
}, {
  prefix: '+358',
  iso2: 'FI'
}, {
  prefix: '+33',
  iso2: 'FR'
}, {
  prefix: '+594',
  iso2: 'GF'
}, {
  prefix: '+689',
  iso2: 'PF'
}, {
  prefix: '+241',
  iso2: 'GA'
}, {
  prefix: '+220',
  iso2: 'GM'
}, {
  prefix: '+995',
  iso2: 'GE'
}, {
  prefix: '+49',
  iso2: 'DE'
}, {
  prefix: '+233',
  iso2: 'GH'
}, {
  prefix: '+350',
  iso2: 'GI'
}, {
  prefix: '+30',
  iso2: 'GR'
}, {
  prefix: '+299',
  iso2: 'GL'
}, {
  prefix: '+1473',
  iso2: 'GD'
}, {
  prefix: '+590',
  iso2: 'GP'
}, {
  prefix: '+502',
  iso2: 'GT'
}, {
  prefix: '+224',
  iso2: 'GN'
}, {
  prefix: '+245',
  iso2: 'GW'
}, {
  prefix: '+592',
  iso2: 'GY'
}, {
  prefix: '+509',
  iso2: 'HT'
}, {
  prefix: '+504',
  iso2: 'HN'
}, {
  prefix: '+852',
  iso2: 'HK'
}, {
  prefix: '+36',
  iso2: 'HU'
}, {
  prefix: '+354',
  iso2: 'IS'
}, {
  prefix: '+91',
  iso2: 'IN'
}, {
  prefix: '+62',
  iso2: 'ID'
}, {
  prefix: '+98',
  iso2: 'IR'
}, {
  prefix: '+964',
  iso2: 'IQ'
}, {
  prefix: '+353',
  iso2: 'IE'
}, {
  prefix: '+972',
  iso2: 'IL'
}, {
  prefix: '+39',
  iso2: 'IT'
}, {
  prefix: '+1876',
  iso2: 'JM'
}, {
  prefix: '+81',
  iso2: 'JP'
}, {
  prefix: '+962',
  iso2: 'JO'
}, {
  prefix: '+7',
  iso2: 'KZ'
}, {
  prefix: '+254',
  iso2: 'KE'
}, {
  prefix: '+686',
  iso2: 'KI'
}, {
  prefix: '+965',
  iso2: 'KW'
}, {
  prefix: '+996',
  iso2: 'KG'
}, {
  prefix: '+856',
  iso2: 'LA'
}, {
  prefix: '+371',
  iso2: 'LV'
}, {
  prefix: '+961',
  iso2: 'LB'
}, {
  prefix: '+266',
  iso2: 'LS'
}, {
  prefix: '+231',
  iso2: 'LR'
}, {
  prefix: '+218',
  iso2: 'LY'
}, {
  prefix: '+423',
  iso2: 'LI'
}, {
  prefix: '+370',
  iso2: 'LT'
}, {
  prefix: '+352',
  iso2: 'LU'
}, {
  prefix: '+853',
  iso2: 'MO'
}, {
  prefix: '+389',
  iso2: 'MK'
}, {
  prefix: '+261',
  iso2: 'MG'
}, {
  prefix: '+265',
  iso2: 'MW'
}, {
  prefix: '+60',
  iso2: 'MY'
}, {
  prefix: '+960',
  iso2: 'MV'
}, {
  prefix: '+223',
  iso2: 'ML'
}, {
  prefix: '+356',
  iso2: 'MT'
}, {
  prefix: '+692',
  iso2: 'MH'
}, {
  prefix: '+596',
  iso2: 'MQ'
}, {
  prefix: '+222',
  iso2: 'MR'
}, {
  prefix: '+230',
  iso2: 'MU'
}, {
  prefix: '+262',
  iso2: 'YT'
}, {
  prefix: '+52',
  iso2: 'MX'
}, {
  prefix: '+691',
  iso2: 'FM'
}, {
  prefix: '+373',
  iso2: 'MD'
}, {
  prefix: '+377',
  iso2: 'MC'
}, {
  prefix: '+976',
  iso2: 'MN'
}, {
  prefix: '+382',
  iso2: 'ME'
}, {
  prefix: '+1664',
  iso2: 'MS'
}, {
  prefix: '+212',
  iso2: 'MA'
}, {
  prefix: '+258',
  iso2: 'MZ'
}, {
  prefix: '+95',
  iso2: 'MM'
}, {
  prefix: '+264',
  iso2: 'NA'
}, {
  prefix: '+674',
  iso2: 'NR'
}, {
  prefix: '+977',
  iso2: 'NP'
}, {
  prefix: '+31',
  iso2: 'NL'
}, {
  prefix: '+599',
  iso2: 'AN'
}, {
  prefix: '+687',
  iso2: 'NC'
}, {
  prefix: '+64',
  iso2: 'NZ'
}, {
  prefix: '+505',
  iso2: 'NI'
}, {
  prefix: '+227',
  iso2: 'NE'
}, {
  prefix: '+234',
  iso2: 'NG'
}, {
  prefix: '+683',
  iso2: 'NU'
}, {
  prefix: '+672',
  iso2: 'NF'
}, {
  prefix: '+850',
  iso2: 'KP'
}, {
  prefix: '+1670',
  iso2: 'MP'
}, {
  prefix: '+47',
  iso2: 'NO'
}, {
  prefix: '+968',
  iso2: 'OM'
}, {
  prefix: '+92',
  iso2: 'PK'
}, {
  prefix: '+680',
  iso2: 'PW'
}, {
  prefix: '+970',
  iso2: 'PS'
}, {
  prefix: '+507',
  iso2: 'PA'
}, {
  prefix: '+675',
  iso2: 'PG'
}, {
  prefix: '+595',
  iso2: 'PY'
}, {
  prefix: '+51',
  iso2: 'PE'
}, {
  prefix: '+63',
  iso2: 'PH'
}, {
  prefix: '+48',
  iso2: 'PL'
}, {
  prefix: '+351',
  iso2: 'PT'
}, {
  prefix: '+974',
  iso2: 'QA'
}, {
  prefix: '+40',
  iso2: 'RO'
}, {
  prefix: '+250',
  iso2: 'RW'
}, {
  prefix: '+685',
  iso2: 'WS'
}, {
  prefix: '+378',
  iso2: 'SM'
}, {
  prefix: '+239',
  iso2: 'ST'
}, {
  prefix: '+966',
  iso2: 'SA'
}, {
  prefix: '+221',
  iso2: 'SN'
}, {
  prefix: '+381',
  iso2: 'RS'
}, {
  prefix: '+248',
  iso2: 'SC'
}, {
  prefix: '+232',
  iso2: 'SL'
}, {
  prefix: '+65',
  iso2: 'SG'
}, {
  prefix: '+421',
  iso2: 'SK'
}, {
  prefix: '+386',
  iso2: 'SI'
}, {
  prefix: '+677',
  iso2: 'SB'
}, {
  prefix: '+252',
  iso2: 'SO'
}, {
  prefix: '+27',
  iso2: 'ZA'
}, {
  prefix: '+82',
  iso2: 'KR'
}, {
  prefix: '+34',
  iso2: 'ES'
}, {
  prefix: '+94',
  iso2: 'LK'
}, {
  prefix: '+290',
  iso2: 'SH'
}, {
  prefix: '+508',
  iso2: 'PM'
}, {
  prefix: '+249',
  iso2: 'SD'
}, {
  prefix: '+597',
  iso2: 'SR'
}, {
  prefix: '+268',
  iso2: 'SZ'
}, {
  prefix: '+46',
  iso2: 'SE'
}, {
  prefix: '+41',
  iso2: 'CH'
}, {
  prefix: '+963',
  iso2: 'SY'
}, {
  prefix: '+886',
  iso2: 'TW'
}, {
  prefix: '+992',
  iso2: 'TJ'
}, {
  prefix: '+255',
  iso2: 'TZ'
}, {
  prefix: '+66',
  iso2: 'TH'
}, {
  prefix: '+228',
  iso2: 'TG'
}, {
  prefix: '+690',
  iso2: 'TK'
}, {
  prefix: '+676',
  iso2: 'TO'
}, {
  prefix: '+216',
  iso2: 'TN'
}, {
  prefix: '+90',
  iso2: 'TR'
}, {
  prefix: '+993',
  iso2: 'TM'
}, {
  prefix: '+688',
  iso2: 'TV'
}, {
  prefix: '+256',
  iso2: 'UG'
}, {
  prefix: '+380',
  iso2: 'UA'
}, {
  prefix: '+971',
  iso2: 'AE'
}, {
  prefix: '+44',
  iso2: 'GB'
}, {
  prefix: '+598',
  iso2: 'UY'
}, {
  prefix: '+998',
  iso2: 'UZ'
}, {
  prefix: '+678',
  iso2: 'VU'
}, {
  prefix: '+58',
  iso2: 'VE'
}, {
  prefix: '+84',
  iso2: 'VN'
}, {
  prefix: '+681',
  iso2: 'WF'
}, {
  prefix: '+967',
  iso2: 'YE'
}, {
  prefix: '+260',
  iso2: 'ZM'
}, {
  prefix: '+263',
  iso2: 'ZW'
}];
export const prefix1 = ['CA', 'US', 'AI', 'AG', 'BS', 'BB', 'BM', 'VG', 'KY', 'DM', 'DO', 'GD', 'JM', 'MS', 'KN', 'LC', 'VC', 'TT', 'TC', 'VI'];