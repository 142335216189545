import Card from 'components/uiKit/card';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import React, { useState } from 'react';
import InputGroup from 'components/uiKit/inputs/inputGroup';
import { prefixIso2Code } from './prefixIso2';
interface Props {
  prefix: string;
  setPrefix: Function;
}
export default function PrefixSelector(props: Props) {
  const {
    prefix,
    setPrefix
  } = props;
  const [filter, setFilter] = useState();
  const list = filter ? prefixIso2Code.filter(item => item.prefix.includes(filter)) : prefixIso2Code;
  return <Grid width="300px" style={{
    maxWidth: 'calc(100dvw - 2rem)'
  }} data-sentry-element="Grid" data-sentry-component="PrefixSelector" data-sentry-source-file="prefixSelector.tsx">
            <Card data-sentry-element="Card" data-sentry-source-file="prefixSelector.tsx">
                <Grid gap="2rem" padding={['p-3']} data-sentry-element="Grid" data-sentry-source-file="prefixSelector.tsx">
                    <Grid data-sentry-element="Grid" data-sentry-source-file="prefixSelector.tsx">
                        <Typography translateGroup="global" translateKey="select-the-prefix" weight={600} algin="center" size="md" data-sentry-element="Typography" data-sentry-source-file="prefixSelector.tsx" />
                    </Grid>
                    <Grid gap="0.5rem" padding={['pb-3']} data-sentry-element="Grid" data-sentry-source-file="prefixSelector.tsx">
                        <Grid data-sentry-element="Grid" data-sentry-source-file="prefixSelector.tsx">
                            <InputGroup id="filter" name="filter" label="filter" value={filter} inputType="number" onChange={({
              target
            }) => {
              setFilter(target.value as any);
            }} data-sentry-element="InputGroup" data-sentry-source-file="prefixSelector.tsx" />
                        </Grid>
                        <Grid gap="0.25rem" style={{
            height: '350px',
            overflowY: 'auto'
          }} data-sentry-element="Grid" data-sentry-source-file="prefixSelector.tsx">
                            {list.map(px => {
              const isSelected = px.prefix === prefix;
              return <Grid key={px.iso2} onClick={() => setPrefix(px.prefix)} verticalAlgin="center" padding={['p-2']} style={{
                borderRadius: '4pt',
                border: 'solid 1px var(--primary)',
                background: isSelected ? 'var(--primary)' : '#fff',
                color: isSelected ? ' #fff' : 'var(--primary)',
                cursor: 'pointer'
              }} wrap="nowrap" gap="0.5rem">
                                            <img src={`/images/flags/${px.iso2.toLowerCase()}.svg`} alt={px.iso2} width="24px" style={{
                  aspectRatio: '4 / 3'
                }} />
                                            <Typography>
                                                {px.prefix}
                                            </Typography>
                                        </Grid>;
            })}
                        </Grid>
                    </Grid>

                </Grid>
            </Card>
        </Grid>;
}