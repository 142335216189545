const charMap: Record<string, string> = {
  '/': 'slash2Q9ac',
  '?': 'question8W7sb',
  ':': 'colon4Z6ud',
  '!': 'Exclamation5X1fe',
  '@': 'at3Y2hg',
  // add more special characters and their codes as needed
}

export function parseToSpecialCode(inputString: string): string {
  let outputString = ''
  let i = 0
  while (i < inputString.length) {
    const char = inputString.charAt(i)
    const charCode = charMap[char]
    if (charCode) {
      outputString += charCode
    } else {
      outputString += char
    }
    i += 1
  }

  return outputString
}

export function revertFromSpecialCode(inputString: string): string {
  let outputString = ''
  let i = 0
  while (i < inputString.length) {
    const charCode = inputString.substr(i, 3)
    const mappedChar = charMap[charCode]
    if (mappedChar) {
      outputString += mappedChar
      i += 3
    } else {
      outputString += inputString.charAt(i)
      i += 1
    }
  }
  return outputString
}

export function createSearchParams(params: Record<string, any>) {
  return new URLSearchParams(
    Object.entries(params).flatMap(([key, values]) => (Array.isArray(values)
        ? values.map((value) => [key, value])
        : [[key, values]])),
  )
}

export function replaceDashesToHyphens(str: string) {
  if (!str) {
    return str
  }
  return str.replace(/_/g, '-')
}

export function replaceHyphensToDashes(str: string) {
  if (!str) {
    return str
  }
  return str.replace(/-/g, '_')
}

export function generateSiteMapIndexes(urls: string[]) {
  return `<?xml version="1.0" encoding="UTF-8"?>
    <sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${urls.map((url) => {
        return `
          <sitemap>
            <loc>${url}</loc>
          </sitemap>
        `
      })
      .join('')}
    </sitemapindex>
  `
}

export function generateSiteMapUrlSets(urls: string[]) {
  const DEFAULT_LANG = 'fi'

  return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:news="http://www.google.com/schemas/sitemap-news/0.9">
      ${urls.map((url) => (`
          <url>
            <loc>${url}</loc>
            <xhtml:link xmlns:xhtml="http://www.w3.org/1999/xhtml" rel="alternate" hreflang="${DEFAULT_LANG}" href="${url}" />
          </url>
        `)).join('')}
    </urlset>
  `
}
